import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import ParticlesWebComponent from '../../../Hosting/ParticleWeb';
import BannerWrapper from '../bannerhome.style';
import { Link } from 'gatsby';
import Homepageimage from 'common/src/assets/image/hosting/sliderv2.svg';

import RAPBotImage from 'common/src/assets/image/hosting/Pages/Misc/Checklist.jpg';
import Achievementimage from 'common/src/assets/image/hosting/news/home_banner_analytics.jpg';
import Achievementimage2 from 'common/src/assets/image/hosting/news/home_banner_cioreview.jpg';
import TypingEffectSection from '../../../Hosting/TypingEffect';
import { height } from 'styled-system';

// import './bannerother.css';

const BannerSectionHome = ({
  row,
  mainrow,
  title,
  description,
  subdescription,
  button,
  textArea,
  advrow,
  advcolimg,
  advcoltext,
  adtext,
  adtitle,
  imagecont,
  column,
  pipe,
}) => {
  return (
    <>
      <BannerWrapper className="banner_container Home">
        {/* backgrount particle animation */}
        <ParticlesWebComponent />
        <div className="home_box">
          <Container>
            <Box {...mainrow} className="no-cursor">
              {/* left row */}
              <Box
                {...row}
                {...imagecont}
                className="main_home_banner_image_parent"
              >
                <img
                  src={Homepageimage}
                  className="main_home_banner_image"
                  alt="Robot assisting humans in automation"
                ></img>
              </Box>
              {/* right row */}
              <Box {...row}>
                <Box {...column}>
                  <Box {...textArea}>
                    <Heading
                      {...title}
                      className="text_center"
                      content="Intelligent Process Automation that delivers"
                    />
                    <TypingEffectSection />
                    <Heading
                      {...description}
                      className="text_center"
                      content="Hyperautomate processes end-to-end"
                    />
                    <div className="empty_space30" />
                  </Box>
                  <Box className="home_button">
                    <Link to="/bookademo" className="btn btn_tertiary">
                      <span className="btn-text">Book a Demo</span>
                    </Link>
                  </Box>

                  <Box {...advrow}>
                    <Box {...advcolimg}>
                      <Link
                        to="/news/analytics-insights-magazine"
                        style={{
                          width: '100%',
                          display: 'block',
                          textAlign: 'end',
                          paddingRight: '10px',
                        }}
                      >
                        <img
                          src={Achievementimage}
                          className="advertisement_image"
                          style={{ height: '90px' }}
                          alt="RAPs'achievement certificate in Analytics insight magazine"
                        ></img>
                      </Link>
                    </Box>
                    <Box {...advcoltext} {...pipe}>
                      <Link
                        to="/news/cio-review"
                        style={{
                          width: '100%',
                          display: 'block',
                          textAlign: 'center',
                          paddingRight: '0px',
                        }}
                      >
                        <img
                          src={Achievementimage2}
                          className="advertisement_image"
                          style={{ height: '90px' }}
                          alt="RAPs'achievement certificate in CIO Review magazine"
                        ></img>
                      </Link>
                      {/* <Heading
                        {...adtitle}
                        content="Recognized among the 10 Most Valuable Digital Transformation Companies for 2020 by The Analytics Insight."
                      /> */}
                      {/* <Link to="/miscellaneous/checklist-for-AI-automation">
                        <Text {...adtext} content={"Click here to checkout"} />
                      </Link> */}
                    </Box>
                  </Box>

                  {/* <div className="empty_space30" /> */}
                  {/* For additional content */}
                  {/* <Box {...mainrow}></Box> */}
                </Box>
              </Box>
            </Box>
          </Container>
        </div>
      </BannerWrapper>
    </>
  );
};

BannerSectionHome.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  subdescription: PropTypes.object,
  button: PropTypes.object,
};

BannerSectionHome.defaultProps = {
  mainrow: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: [1, '100%', '100%', '100%', '100%'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['100%', '100%', '50%', '50%', '50%'],
    position: 'relative',
  },
  column: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: ['100%', '100%', '100%', '100%', '100%'],
    pt: ['10px', '10px', '75px', '75px', '75px'],
    position: 'relative',
  },
  textArea: {
    width: [1, '100%', '100%', '100%', '100%'],
  },
  title: {
    as: 'h1',
    fontSize: ['26px', '26px', '26px', '26px', '26px'],
    fontWeight: '700',
    // color: '#0f2137',
    // color: "#fff",
    color: '#000000',
    letterSpacing: '-0.025em',
    mt: ['20px', '20px', '25px', '25px', '25px'],
    // mb: ['20px', '20px', '25px', '25px', '25px'],
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  description: {
    as: 'h2',
    fontSize: ['26px', '26px', '26px', '26px', '26px'],
    // color: '#343d48cc',
    // color: "#fff",
    color: '#000000',
    lineHeight: '1.2',
    // mb: ['8px', '8px', '10px', '10px', '10px'],
    mb: '2px',
    textAlign: 'center',
  },
  subdescription: {
    fontSize: ['14px', '14px', '14px', '16px', '16px'],
    // color: '#343d48cc',
    color: '#fff',
    lineHeight: '1.75',
    mb: ['20px', '20px', '25px', '25px', '25px'],
    textAlign: 'center',
  },
  button: {
    title: 'Search',
    type: 'button',
    fontSize: '18px',
    fontWeight: '500',
    color: '#fff',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    iconPosition: 'left',
  },
  advrow: {
    mt: '15px',
    mb: '20px',
    width: '70%',
    flexBox: true,
    flexWrap: 'wrap',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  advcolimg: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
  },
  advcoltext: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    p: '10px',

    flexBox: true,
    alignItems: 'center',
  },
  adtitle: {
    fontSize: ['12px', '12px', '12px', '12px', '12px'],
    fontWeight: 'bold',
    // color: '#0f2137',
    color: '#000000',
    // letterSpacing: "-0.025em",
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    m: '0px !important',
  },
  adtext: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: 'normal',
    // color: '#0f2137',
    color: '#393E99',
    letterSpacing: '-0.025em',
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  imagecont: {
    // position: "absolute",
    // ml: "-150px",
  },
  pipe: {
    borderLeft: ['none', 'none', 'none', '1px solid grey', '1px solid grey'],
  },
};

export default BannerSectionHome;
